// RouteChangeDetector.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Analytics() {
  const location = useLocation();

  useEffect(() => {
    // Function to execute before route change
    const handleRouteChange = async (location) => {
      const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');
        acc[name] = decodeURIComponent(value);
        return acc;
      }, {});

      if (cookies.analytics) {
        let data = JSON.parse(cookies.analytics);
        data.et = Date.now();

        await fetch('/api/analytics/add', {
          method: 'POST',
          headers: {
            'User-Agent': navigator.userAgent,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
      }
      // Perform any additional actions you need here
    };

    // Listen for route changes
    handleRouteChange(location);
  }, [location]);

  useEffect(() => {

    // Perform actions based on the new location
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');
        acc[name] = decodeURIComponent(value);
        return acc;
      }, {});

    const startDate = Date.now();
    const page = window.location.href;
    const updateAnalyticsData = async () => {
      let device;
      const width = window.screen.width;

      if (width <= 600) {
        device = 'mobile';
      } else if (width > 600 && width <= 992) {
        device = 'tablet';
      } else if (width > 992) {
        device = 'laptop/desktop';
      }

      let data = {};

      if (cookies.cookies == 'true' ){
        data = {
            st: startDate,
            page: page,
            referrer: document.referrer,
            device: device,
            consent: true
        };
      }

      else{
        data = {
            st: startDate,
            page: page,
            referrer: document.referrer,
            device: null,
            consent: false
        };
      }

      document.cookie = "analytics=" + encodeURIComponent(JSON.stringify(data)) + "; path=/";
    };

    updateAnalyticsData();
  }, [location]);

  return null;
}

export default Analytics;
