import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';

function Hamburger({isOpen}){
    return (
        <section className="hamburger">
            {isOpen ? <FontAwesomeIcon icon={faX} className='hamburger' />: <FontAwesomeIcon icon={faBars} className='hamburger' />}
        </section>
    )
}

export default Hamburger