import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/footer';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ProtectedRoute from './components/protectedRoute';
import Admin from './pages/admin';
import Dashboard from './pages/dashboard';
import Nopage from'./pages/nopage';
import CookieBanner from './pages/cookie-banner';
import Analytics from './components/analytics';

import './App.css';

export default function App(){

    return(
        <>
        <CookieBanner />
        <BrowserRouter>
            <Analytics />
            <Routes>
                <Route path="/" element={<Navbar/>}>
                    <Route index element={<Home />} />
                    <Route path='projects' element={<Projects />} />
                    <Route path='login' element={<Login />} />
                    <Route path='logout' element={<Logout />} />
                    <Route path='admin' element={<ProtectedRoute><Admin /></ProtectedRoute>} /> 
                    <Route path='Dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />  
                    <Route path='*' element={<Nopage />} />                  
                </Route>
            </Routes>
        </BrowserRouter>
        <Footer />
        </>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);