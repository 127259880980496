import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

function Nopage(){
    return(
        <main id='loginMain'>
            <section id='loginForm' className='nopage'>
                <h1>404</h1>
                <h2>Sorry!</h2>
                <p id="errText">The page you are looking for cannot be found.</p>
                <a href="/"><FontAwesomeIcon icon={faHouse} />   Home</a>
            </section>
        </main>
    );
}

export default Nopage;