import React, { useState } from 'react';
import  { redirect } from 'react-router-dom'
import Logo from '../assets/logo.webp';

function Login(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const postForm = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/auth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            if (response.ok) {
                var data = await response.json();
                document.querySelector('#err').innerHTML = "Login Successful";
                sessionStorage.setItem("JWT", data['Token']);

                redirect("/admin");

                window.location.href = '/admin';

            } else {
                var message = await response.json();
                document.querySelector('#err').innerHTML = message['message'];
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <main id="loginMain">
            <form id="loginForm" onSubmit={postForm}>
                <img src={Logo} alt="Logo" className='logo' />

                <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  id="email" placeholder="Email" />
                <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} 
                id="password" placeholder="Password" />
                <input type="submit" value="Login" id="loginBtn"/>

                <p id='err'></p>
            </form>
        </main>
    )
}

export default Login;