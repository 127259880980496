
function CookieBanner(){

    const setCookies = (e) => {
        e.preventDefault();

        const data = new FormData(document.querySelector('#cookies'))
        let value = data.get('analytics') == 'on' ? 'true': 'false'

        document.cookie = 'cookies=' + value + '; path=/';

        window.location.reload();
    }

    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');
        acc[name] = decodeURIComponent(value);
        return acc;
    }, {});

    return (
    <dialog open={!cookies.cookies}>
        <h1>Cookies on {window.location.href.split('/')[2]}</h1>
        <p>We use some essential cookies to make this service work.</p>
        <p>We'd also like to use analytics cookies so we can understand how you use the service and make improvements.</p>
        <form action="" onSubmit={(e) => setCookies(e)} id="cookies">
            <section className="options">
                <p>Essential</p>
                <label className="switch">
                    <input type="checkbox" checked disabled />
                    <span className="slider round" id="disabled"></span>
                </label>
            </section>
            <section className="options">
                <p>Analytics</p>
                <label className="switch">
                    <input type="checkbox" name="analytics" defaultChecked />
                    <span className="slider round"></span>
                </label>
            </section> 
            <button type="submit" className="save">Save & Continue</button>
        </form>
    </dialog>
)
}

export default CookieBanner;