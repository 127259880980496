import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';

function Dashboard() {
  const [stats, setStats] = useState({});
  const [chartData, setChartData] = useState([['Date', 'Views']]);
  const [time, setTime] = useState('7');
  const [Data, setData] = useState();
  const [geoData, setGeoData] = useState();

  useEffect(() => {
    async function fetchStats() {
      let r = await fetch('/api/analytics/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: sessionStorage.getItem('JWT')
        })
    });
      let json = await r.json();
      setStats(json);
    }

    fetchStats();
  }, []);

  useEffect(() => {
    async function fetchDataForChart() {
      let response = await fetch('/api/analytics/graph?time=' + time, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: sessionStorage.getItem('JWT')
        })
    });
      let json = await response.json();
      let dataArray = [
        ['Date', 'Views'],
        ...json.map(item => [new Date(item.DATE), item.VIEWS])
      ];

      let req = await fetch('/api/analytics/devices', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: sessionStorage.getItem('JWT')
        })
    });
      let res = await req.json();
      let data = [
        ['Device', 'Count'],
        ...res.map(item => [item.deviceType, item.count])
      ];

      let geo = await fetch('/api/analytics/countries', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: sessionStorage.getItem('JWT')
        })
    });
      let resData = await geo.json();

      let geoarr = [
        ['Country', 'views'],
        ...resData.map(item =>
            [item.country, item.views]
        )
      ]

      setGeoData(geoarr);
      setData(data);
      setChartData(dataArray);
    }

    

    fetchDataForChart();
  }, [time]);

  const options = {
    curveType: 'function',
    title: '',
    titleTextStyle: { color: 'white' },
    hAxis: {
      title: 'Date',
      format: 'MMM dd, yyyy',
      textStyle: { color: 'white' },
      gridlines: { color: 'transparent' }
    },
    vAxis: {
      title: 'Views',
      textStyle: { color: 'white' }
    },
    legend: 'none',
    backgroundColor: 'transparent',
    width: '100%',
    height: '650px',
    tooltip: { isHtml: true }
  };

  const deviceOptions = {
    curveType: 'function',
    title: '',
    titleTextStyle: { color: 'white' },
    hAxis: {
      title: 'Date',
      textStyle: { color: 'white' },
      gridlines: { color: 'transparent' }
    },
    vAxis: {
      title: 'Views',
      textStyle: { color: 'white' }
    },
    //legend: 'none',
    backgroundColor: 'transparent',
    width: '50%',
    height: '700px',
    tooltip: { isHtml: true },
    is3D: true
  };

  const geoOptions = {
    curveType: 'function',
    title: '',
    titleTextStyle: { color: 'white' },
    hAxis: {
      title: 'Date',
      textStyle: { color: 'white' },
      gridlines: { color: 'transparent' }
    },
    vAxis: {
      title: 'Views',
      textStyle: { color: 'white' }
    },
    legend: 'none',
    backgroundColor: 'transparent',
    width: '50%',
    height: '700px',
    tooltip: { isHtml: true }
  };

  return (
    <main className="app">
      <article className='article'>
        <section className='analytics_buttons'>
          <h1 className='dheading'>Bounce Rate (1/wk)</h1>
          <p id="br" style={{ color: stats.B_COLOUR }}>{stats.BOUNCE_RATE_1W}</p>
          <p className="pChange" id="bounce">{stats.B_CHANGE}</p>
        </section>
        <section className='analytics_buttons'>
          <h1 className='dheading'>Visitors (1/wk)</h1>
          <p id="visitors" style={{ color: stats.TV_COLOUR }}>{stats.VISITORS_1W}</p>
          <p className="pChange" id="vis">{stats.TV_CHANGE}</p>
        </section>
        <section className='analytics_buttons'>
          <h1 className='dheading'>Views (1/wk)</h1>
          <p id="views" style={{ color: stats.V_COLOUR }}>{stats.VIEWS_1W}</p>
          <p className="pChange" id="vws">{stats.V_CHANGE}</p>
        </section>
        <section className='analytics_buttons'>
          <h1 className='dheading'>AVG Session Duration (1/wk)</h1>
          <p id="avg" style={{ color: stats.PT_COLOUR }}>{stats.AVG_SESSION_TIME_1W}</p>
          <p className="pChange" id="sdur">{stats.PT_CHANGE}</p>
        </section>
      </article>
      <article className="graph">
        <section className="option">
          <form action="" className='form'>
            <select name="frequency" id="freq" onChange={(e) => setTime(e.target.value)}>
              <option value="7">7 Day</option>
              <option value="28">4 Week</option>
              <option value="91">13 Week</option>
            </select>
          </form>
        </section>
        <Chart
          chartType="LineChart"
          data={chartData}
          options={options}
          id="myChart"
        />
      </article>
      <article className="graphs">
        <Chart
          chartType="PieChart"
          data={Data}
          options={deviceOptions}
          id="myChart"
        />
        <Chart
          chartType="GeoChart"
          data={geoData}
          options={geoOptions}
          id="myChart"
        />
      </article>
    </main>
  );
}

export default Dashboard;