import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import  { redirect } from 'react-router-dom';

const getData = async (theme) => {
    try {
        let response = await fetch('/api/' + theme);
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        let data = await response.json();
        return data;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
};


function Admin(){

    const [aboutDesc, setAboutDesc] = useState('');
    const [experience, setExperience] = useState([]);
    const [projects, setProjects] = useState([]);
    const [education, setEducation] = useState([]);
    const [selectedEducationDesc, setSelectedEducationDesc] = useState('');
    const [selectedExperienceDesc, setSelectedExperienceDesc] = useState('');
    const [selectedProjectsDesc, setSelectedProjectsDesc] = useState('');
    

    useEffect(() => {
        const aboutDescription = async () => {
            const data = await getData('about');
            setAboutDesc(data[0]['DESCRIPTION'])
        }


        aboutDescription();
    })

    useEffect(() => {
        const experiencearr = async () => {
            const data = await getData('experience');
            setExperience(data);
        };

        const projectsarr = async () => {
            const data = await getData('projects');
            setProjects(data);
        };

        const educationarr = async () => {
            const data = await getData('education');
            setEducation(data);
        };

        experiencearr();
        educationarr();
        projectsarr();
    },[])

    const getEducationOnPost = async (title) => {
        if (title == 'new' || title == ''){
            setSelectedEducationDesc('')
            return;
        }

        const educationData = await getData('education');
        const found = educationData.find(item => item.qualification === title);
        setSelectedEducationDesc(found);
    };

    const getExperienceOnPost = async (title) => {
        if (title == 'new' || title == ''){
            setSelectedExperienceDesc('')
            return
        }
        const educationData = await getData('experience');
        const found = educationData.find(item => item.company === title);
        setSelectedExperienceDesc(found);
    };

    const getProjectsOnPost = async (title) => {
        if (title == 'new' || title == ''){
            setSelectedProjectsDesc('')
            return
        }
        const educationData = await getData('projects');
        const found = educationData.find(item => item.title === title);
        setSelectedProjectsDesc(found);
    };

    
    const [fileName, setFileName] = useState('No File Available');
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : 'No File Available');
    };

    const [about, setAbout] = useState('');
    const [action, setAction] = useState('');

    const handleAboutChange = (e) => {
        setAbout(e.target.value);
    };

    const editAbout = async (e) => {
        e.preventDefault();
        const response = await fetch('/api/about', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                about: about,
                action: action,
                token: sessionStorage.getItem('JWT')
            })

        
        });
    }

    const [date, setDate] = useState('');

    const handleDateChange = (e) => {
        setDate(e.target.value);
    }

    const [qualification, setQualification] = useState('');
    const [educationDesc, setEducationDesc] = useState('');

    const handleQualificationChange = (e) => {
        setQualification(e.target.value);
    }

    const handleEducationDescChange = (e) => {
        setEducationDesc(e.target.value);
    }

    const editEducation = async (e) => {
        e.preventDefault();

        const data = new FormData(document.querySelector('#edu'));

        const response = await fetch('/api/education', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                qualification: data.get('qualification'),
                date: data.get('date'),
                description: data.get('Education'),
                action: action,
                token: sessionStorage.getItem('JWT')
            })

        
        });
    }

    const [experienceFormData, setExperienceFormData] = useState({});

    const handleExperienceChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setExperienceFormData(values => ({...values, [name]: value}))
    }

    const editExperience = async (e) => {
        e.preventDefault();
        
        const data = new FormData(document.querySelector('#exp'));

        const response = await fetch('/api/experience', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                company: data.get('company'),
                date: data.get('date'),
                description: data.get('expierienceDesc'),
                action: action,
                token: sessionStorage.getItem('JWT')
            })

        
        });

        
    }

    const editProjects = async (e) => {
        e.preventDefault();
        
        let data = new FormData(document.querySelector('#proj'));
        data.append('action', action)
        data.append('token', sessionStorage.getItem('JWT'));

        const response = await fetch('/api/projects', {
            method: 'POST',
            body: data
        
        });

        
    }

    return (
        <main>
            <section className="container">
                <h1 className="heading">About</h1>
                <form action="" method='' onSubmit={editAbout}>
                    <textarea name="about" id="" placeholder="Start typing..." defaultValue={aboutDesc} onChange={handleAboutChange} ></textarea>
                    <section className='buttons'>
                        <input type="submit" value="Update" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                    </section>
                </form>
            </section>

            <section className="container">
                <h1 className="heading">Education</h1>
                <form action="">
                    <select name="option" id="" onChange={(e) => getEducationOnPost(e.target.value)}>
                        <option value="">-- Select Qualification --</option>
                        <option value="new">-- Add Qualification --</option>
                        {education.map((edu, idx) => (
                            <option key={idx} value={edu.qualification}>{edu.qualification}</option>
                        ))}
                    </select>
                </form>
                <form action="" onSubmit={editEducation} id='edu'>
                    <input type="text" name="qualification" id="" defaultValue={selectedEducationDesc != '' ? selectedEducationDesc.qualification : ''} className='inptTxt' placeholder='qualification' onSubmit={handleQualificationChange} />
                    <br />
                    <input type="text" name="date" id="" className='inptTxt' defaultValue={selectedEducationDesc != '' ? selectedEducationDesc.date : ''} placeholder='Date' onSubmit={handleDateChange}/>
                    
                    <textarea name="Education" id="" placeholder="Start typing..." defaultValue={selectedEducationDesc != '' ? selectedEducationDesc.description : ''} onSubmit={handleEducationDescChange}></textarea>
                    <section className='buttons'>
                        <input type="submit" value="Add" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                        <input type="submit" value="Update" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                        <input type="submit" value="Delete" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                    </section>
                </form>
            </section>

            <section className="container">
                <h1 className="heading">Expierience</h1>
                <form action="">
                    <select name="option" id="" onChange={(e) => getExperienceOnPost(e.target.value)}>
                        <option value="">-- Select Expierience --</option>
                        <option value="new">-- Add Expierience --</option>
                        {experience.map((exp, idx) => (
                            <option key={idx} value={exp.company}>{exp.company}</option>
                        ))}
                    </select>
                </form>
                <form action="" onSubmit={editExperience} id='exp'>
                    <input type="text" name="company" id="" defaultValue={selectedExperienceDesc != '' ? selectedExperienceDesc.company : ''} className='inptTxt' placeholder='Company Name' onChange={handleExperienceChange} />
                    <br />
                    <input type="text" name="date" id="" className='inptTxt' defaultValue={selectedExperienceDesc != '' ? selectedExperienceDesc.date : ''} placeholder='Date' onChange={handleExperienceChange} />
                    <textarea name="expierienceDesc" id="" placeholder="Start typing..." defaultValue={selectedExperienceDesc != '' ? selectedExperienceDesc.description : ''} onChange={handleExperienceChange}></textarea>
                    <section className='buttons'>
                        <input type="submit" value="Add" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                        <input type="submit" value="Update" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                        <input type="submit" value="Delete" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                    </section>
                </form>
            </section>

            <section className="container">
                <h1 className="heading">Projects</h1>
                <form action="">
                    <select name="option" id="" onChange={(e) => getProjectsOnPost(e.target.value)}>
                        <option value="">-- Select Project --</option>
                        <option value="new">-- Add Project --</option>
                        {projects.map((project, idx) => (
                            <option key={idx} value={project.title}>{project.title}</option>
                        ))}
                    </select>
                </form>
                <form action="" onSubmit={editProjects} id='proj' encType="multipart/form-data">
                    <input type="text" name='title' defaultValue={selectedProjectsDesc != '' ? selectedProjectsDesc.title : ''} className='inptTxt'/>
                    <textarea name="description" id="" placeholder="Start typing..." defaultValue={selectedProjectsDesc != '' ? selectedProjectsDesc.description : ''}></textarea>
                    <section className="fileUpload">
                        <input type="file" name="file" id="file" onChange={handleFileChange} />
                        
                        {selectedProjectsDesc && selectedProjectsDesc.image && selectedProjectsDesc != '' ? (
                        <img src={'/api/img/' + selectedProjectsDesc.image} alt="" id='img'/>
                        ) : (
                            <section id='cloud'>
                                <FontAwesomeIcon icon={faCloud} style={{ color: "#ffffff" }} />
                                <p>{fileName}</p>
                            </section>
                        )}
                    </section>
                    <section className='buttons'>
                        <input type="submit" value="Add" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                        <input type="submit" value="Update" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                        <input type="submit" value="Delete" name='action' className='button' onClick={(e) => setAction(e.target.value)} />
                    </section>
                </form>
            </section>


        </main>
    )
}

export default Admin;