import React from 'react'
import  { redirect } from 'react-router-dom'

function Logout(){
    sessionStorage.removeItem('JWT');

    redirect("/");

    window.location.href = '/'

}

export default Logout;