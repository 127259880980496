function ProjectsAPI() {
    return fetch('/api/projects')
        .then(response => {
            if (!response.ok) {
                throw new Error(`Network response was not ok, status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            
            return data;
        })
        .catch(error => {
            console.log("An error occurred", error);
            throw error;
        });
}

export default ProjectsAPI;
