import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <footer>
            <h2>Contact Me</h2>
            <ul className='contactMe'>
                <li className='elements'><a href="https://github.com/Factual7418?tab=repositories"><FontAwesomeIcon icon={faGithub} /> Github</a></li>
                <li className='elements'><a href="https://www.linkedin.com/in/william-moses-80033a26a"><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a></li>
                <li className='elements'><a href="mailto:willrmoses@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> willrmoses@gmail.com</a></li>
            </ul>
        </footer>
    );
}

export default Footer;