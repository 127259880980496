import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Logo from '../assets/logo.webp';
import Hamburger from './hamburger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faRightFromBracket, faChartSimple } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem('JWT');
            if (token) {
                try {
                    const resp = await fetch('/api/verify', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ token })
                    });
                    const data = await resp.json();
                    setIsLoggedIn(data.auth);
                } catch (error) {
                    console.error('Error verifying token:', error);
                    setIsLoggedIn(false);
                }
            }
        };

        checkAuth();
    }, []);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    };

    return (
        <>
            <style>{`
                @media only screen and (max-width: 600px) {
                    #links ul {
                        display: ${hamburgerOpen ? 'flex' : 'none'};
                        height: 100vh;
                        width: 100vw;
                        top: 0;
                        left: 0;
                        margin-top: 60px;
                        /*margin-right: 50vw;*/
                        position: absolute;
                        background-color: #282c34;
                        z-index: 1000;
                        padding: 0;

                        flex-direction: column;
                        flex-wrap: wrap;
                        align-items: center;
                        overflow: hidden;
                    }

                    #links ul li {
                        display: block;
                        border-bottom: lightgrey 1px solid;
                        width: 100%;
                        text-align: center;
                        padding: 5px;
                        margin: 5px;
                        margin-left: 0;
                        margin-right: 0;
                    }

                    #links ul li:hover {
                        border-radius: 4px;
                        background-color: #3a3f4b;
                    }

                    #loginTxt {
                        display: inline;
                        margin-left: 5px;
                    }
                }
            `}</style>
            <header>
                <nav>
                    <img src={Logo} alt="logo" id="logo" />

                    <section id="links">
                        <ul>
                            <li className="lnk" onClick={toggleHamburger}><Link to="/">About Me</Link></li>
                            <li className="lnk" onClick={toggleHamburger}><Link to="/projects">Projects</Link></li>
                            {isLoggedIn ? (
                                <>
                                    <li className="lnk"><Link to="/admin" onClick={toggleHamburger}>Admin</Link></li>
                                    <li className='lnk'><Link to='/Dashboard' onClick={toggleHamburger}><FontAwesomeIcon icon={faChartSimple} /> Analytics</Link></li>
                                    <li id="user"><Link to="/logout" onClick={toggleHamburger}><FontAwesomeIcon icon={faRightFromBracket} /><p id="loginTxt">Logout</p></Link></li>
                                </>
                            ) : ''}
                        </ul>
                        <section onClick={toggleHamburger}>
                            <Hamburger isOpen={hamburgerOpen} />
                        </section>
                    </section>
                </nav>
            </header>
            <Outlet />
        </>
    );
}

export default Navbar;
