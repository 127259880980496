import React, { useState, useEffect } from 'react';
import ProjectsAPI from '../components/api';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        ProjectsAPI()
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
                setProjects(data);
            })
            .catch(error => {
                setError(error.message);
            });
    }, []);

    return (
        <main className="projects">
            {error ? (
                <p>{error}</p>
            ) : (
                projects.map((project, index) => (
                    <article key={index} className="project">
                        <img src={'/api/img/' + (project.image != null ? project.image : 'code.jpg')} alt={project.title} className='projectImg'/>
                        <h2>{project.title}</h2>
                        <p>{project.description}</p>
                        <br />
                    </article>
                ))
            )}
        </main>
    );
}

export default Projects;
