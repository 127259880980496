import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGraduationCap, faCalendarDays, faSuitcase} from '@fortawesome/free-solid-svg-icons';
import Image from '../assets/me.jpg';

const getData = async (theme) => {
    try {
        let response = await fetch('/api/' + theme);
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        let data = await response.json();
        return data;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
};

const Home = () => {

    const [aboutDesc, setAboutDesc] = useState('');
    const [experience, setExperience] = useState([]);
    const [projects, setProjects] = useState([]);
    const [education, setEducation] = useState([]);

    useEffect(() => {
        const aboutDescription = async () => {
            const data = await getData('about');
            setAboutDesc(data[0]['DESCRIPTION'])
        }


        aboutDescription();
    })

    useEffect(() => {
        const experiencearr = async () => {
            const data = await getData('experience');
            setExperience(data);
        };

        const projectsarr = async () => {
            const data = await getData('projects');
            setProjects(data);
        };

        const educationarr = async () => {
            const data = await getData('education');
            setEducation(data);
        };

        experiencearr();
        educationarr();
        projectsarr();
    },[])


    return (
        <main>
            <article className="aboutMe">
                <section className="circle">
                    <img src={Image} alt="" id="image" />
                </section>
                <br />
                <p>{aboutDesc}</p>
            </article>
            <article className="education">
                <h2><FontAwesomeIcon icon={faGraduationCap} /> Education</h2>
                <br />
                {education.map((edu, idx) => (
                    <>
                    <article>
                        <h4>{edu.qualification}</h4>
                        <p id='date'><FontAwesomeIcon icon={faCalendarDays} /> {edu.date}</p>
                        <ul>
                            {
                                edu.description.split(', ').map((itm, idx) =>(
                                    <li key={idx}>{itm}</li>
                                ))
                            }
                        </ul>
                    </article>
                    <hr />
                    </>
                ))}
            </article>
            <article className='expierience'>
                <h2><FontAwesomeIcon icon={faSuitcase} /> Work Experience</h2>
                <br />
                {experience.map((exp, idx) => (
                    <>
                    <article key={idx}>
                        <h4>{exp.company}</h4>
                        <p id="date"><FontAwesomeIcon icon={faCalendarDays} /> {exp.date}</p>
                        <ul>
                            {exp.description.split(', ').map((itm, idx) => (
                                <li>{itm}</li>
                            ))}
                        </ul>
                    </article>
                    <hr />
                    </>
                ))}
            </article>
        </main>
    );
}

export default Home;